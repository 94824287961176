.PageDatabaseJobsNew .JobNew {
	display: flex;
	flex-direction: row;
	column-gap: 1em;
}

.PageDatabaseJobsNew ul.availableJobs {
	width: 40em;
	margin: 1ex;
	padding: 0.5ex;
	height: 100%;
	overflow-y: scroll;
	xbox-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.PageDatabaseJobsNew ul.availableJobs li {
	list-style-type: none;
	padding: 1ex;
	margin: 1ex;
	background-color: #eee;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	cursor: pointer;
	transition-duration: 0.3s;
}
.PageDatabaseJobsNew ul.availableJobs li:hover {
	background-color: white;
}
.PageDatabaseJobsNew ul.availableJobs li.selected {
	color: white;
	background-color: var(--uq-secondary-lightpurple);
}

.PageDatabaseJobsNew ul.availableJobs h4 {
	xfont-size: 100%;
	margin: 0;
}

.PageDatabaseJobsNew .fields {
	width: 100%;
}
.PageDatabaseJobsNew .fields .value input[type="date"],
.PageDatabaseJobsNew .fields .value input[type="datetime-local"] {
	width: 20em;
}

.PageDatabaseJobsNew .fields .name {
	padding-top: 1ex;
	width: 10em;
	vertical-align: top;
	font-weight: bold;
}
.PageDatabaseJobsNew .fields .value {
	display: flex;
	flex-direction: column;
	padding-bottom: 2ex;
}
.PageDatabaseJobsNew .fields .value span {
	padding-top: 0.5ex;
}
