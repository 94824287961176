.DeviceList {
	width: 100%;
	display: flex;
	flex-direction: column;
}

/* Shrink all the table columns except one */
.DeviceList table .icon { width: 1px; }
.DeviceList table .id   { width: 1px; }
.DeviceList table .stat { width: 1px; }
.DeviceList table .code { width: 1px; }

.DeviceList table td.stat { text-align: center;}

.DeviceList tr.gateway {
	background-color: var(--table-row-groupheader);
}

.DeviceList tr.pad td {
	padding-top: 1ex;
}

.DeviceList td {
	/* Disable all padding so there are no parts of the cell that can't be
	   clicked as a link. */
	padding: 0;
}

.DeviceList tr.meter td a {
	padding-left: 1em; /* Indent meters under gateways */
}

.DeviceList table td a {
	/* Simulate spacing between cells, but do it as a border on the link
	   so that it's still clickable. */
	/*border-right: 2px solid white;*/
	/* Put the cell padding inside the link so it remains clickable. */
	padding: 0 0.5ex 0 0.5ex;
}

.DeviceList a {
	display: block; /* Make links take up the whole table cell */
	color: inherit;
	text-decoration: none;
}

.DeviceList .no-devices {
	padding: 1ex;
}
