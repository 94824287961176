li.JobListEntry {
	display: flex;
	flex-direction: row;
	padding: 1ex;
	margin: 0 0 1ex 0;
	justify-content: space-between;
	background-color: var(--table-row-groupheader);
	transition-duration: 0.2s;

	border-left: var(--border-accent-neutral);
}
li.JobListEntry.JobFailed {
	border-left: var(--border-accent-error);
}
li.JobListEntry.JobDelayed {
	border-left: var(--border-accent-warning);
}
li.JobListEntry.JobActive {
	border-left: var(--border-accent-success);
}
li.JobListEntry.highlight {
	animation: highlight 3s;
}

li.JobListEntry:hover {
	background-color: var(--table-row-hover);
}

.JobListEntry i {
	cursor: default;
	font-style: normal;
	margin-right: 1ex;
}

.JobListEntry .left {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
}

.JobListEntry .subtitle {
	display: flex;
	flex-direction: row;
	opacity: 0.75;
}

.JobListEntry h4,
.JobListEntry h5 {
	margin: 0;
	padding: 0;
	width: 100%;
	font-size: inherit;
}
.JobListEntry h4 {
	font-weight: bold;
}
.JobListEntry h5 {
	font-weight: normal;
	font-style: italic;
}

.JobListEntry .subtitle div {
	margin-right: 1em;
}

.JobListEntry .subtitle .error {
	color: var(--error-text);
}

.JobListEntry .percentComplete {
	margin: 0 1ex;
	float: right;
}

@keyframes highlight {
	0% { background-color: var(--highlight-fade-start); }
	100% { background-color: var(--table-row-groupheader); }
}
