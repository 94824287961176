.DeviceSearchFields {
	display: flex;
	flex-direction: row;
	background-color: var(--uq-purple);
	padding: 0 1em 1em 1em;
}

.DeviceSearchFields .field-action,
.DeviceSearchFields .field {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin: 0 1ex 0 0;
	padding: 1ex;

	position: relative; /* required for ::before to overlap */
}

/* Make everything inside the field sit on top of the background */
.DeviceSearchFields .field * {
	z-index: 2;
}

/* Make the background behind each field darker */
.DeviceSearchFields .field::before {
	/* Make this element exactly and fully overlap the .field */
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;

	/* Attributes to apply to just the background and not the elements */
	background-color: var(--field-background-colour);
	border-radius: 8px;
	opacity: var(--field-background-opacity);
}

.DeviceSearchFields .field label {
	font-weight: bold;
	font-size: 80%;
	color: white;
	padding-bottom: 1ex;
}

.DeviceSearchFields .field.id,
.DeviceSearchFields .field.code {
	width: 6em;
}
