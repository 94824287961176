.Panel {
	xdisplay: flex;
	xflex-direction: column;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: min-content 1fr;

	background-color: var(--uq-secondary-blue);
	border-radius: 1ex;
	padding: 2ex;
	color: white;

	/* Don't add any overflow or contain properties here, otherwise it will
	   interfere with the tooltips (truncating them). */
}

.Panel .title {
	font-weight: bold;
	font-size: 100%;
	margin: 0;

	grid-column: 1 / 1;
	grid-row: 1 / 1;
}

.Panel .title-right {
	text-align: right;
	grid-column: 2 / 2;
	/* Span 2 so that tall content doesn't push the main content lower. */
	grid-row: 1 / span 2;
}

.Panel .content {
	margin-left: 2ex;

	grid-column: 1 / span 2;
	grid-row: 2 / 2;
}

.Panel h3 {
	font-weight: bold;
	font-size: 85%;
	margin: 1.5ex 0 0 0;
}

.Panel p {
	margin: 0;
}

.Panel a {
	color: #ffa;
}

.Panel a:visited {
	color: #fbf;
}

.Panel a:hover {
	color: #f8f;
}

/* Form controls within a panel */
.Panel input {
	height: 100%;
}

.Panel input {
	color: white;
	border: none;
	background: #0002;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

.Panel input:focus {
	outline: 1px solid #fff5;
}

.Panel .Button.link {
	color: white;
}
