.QuickOpenBar {
	position: absolute;
	top: 0;
	right: 0;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin: 1ex;
}

.QuickOpenBar .error {
	border: 1px solid white;
	background-color: var(--error-bg);
	color: var(--error-fg);
	padding: 0.5ex;
	border-radius: 4px;
}

.QuickOpenBar .error.hide {
	display: none;
}

.QuickOpenBar .error ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.QuickOpenBar .error ul li:before {
	content: '⚠️';
	margin: 0 1ex;
	/* Put a darker colour behind it so that it stands out, otherwise the yellow
	   from the icon blends in with the red background and it's unclear. */
	filter: drop-shadow(0px 0px 4px #0005);
}
