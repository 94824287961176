.PageDeviceView .PageHeader .name {
	margin-left: 0.8ex;
}

.PageDeviceView .PageHeader .code {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin: 0 0.5ex 0 0;
	padding: 0.5ex 0.8ex;

	color: #fffe;

	background-color: #0005;
	border-radius: 8px;
}
