.Panel .saveCancel {
	/* Make this appear on top of the main content, so clicking the lower part
	   of the save/cancel buttons works. */
	position: relative;
	z-index: 2;
}

.Panel .saveCancel .saving .icon {
	margin-left: 1ex;
}

.Panel .saveCancel .saving .text {
	font-size: 85%;
}

.Panel input::placeholder {
	font-style: italic;
}

.Panel input {
	/* Large enough to make "Unknown" placeholder visible. */
	min-width: 5.5em;
}
