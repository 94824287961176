.Popup {
	display: inline;
	position: relative;
	padding: 0 1ex;
}

.Popup .popup-content {
	position: absolute;

	display: inline-block;
	padding: 1ex;
	margin-left: 1ex;

	width: max-content;
	max-width: 22em;

	border-radius: 4px;
	box-shadow: #4046 1px 2px 6px 0px;
	font-size: 10pt;

	text-align: left;
	transform: translateY(1ex);
}

/* Various colour styles based on 'type' prop. */
.Popup .popup-content.tooltip {
	background: var(--uq-secondary-yellow);
	color: black;
}
.Popup .popup-content.error {
	background: var(--uq-secondary-red);
	color: white;
}

/* Various alignment options based on 'align' prop. */
.Popup.align-middle .popup-content {
	/* Centre vertically */
	top: 50%;
	transform: translateY(-50%);
}
