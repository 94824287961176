.DeviceView {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.DeviceView .main {
	flex-grow: 1;
	overflow: scroll;

	display: flex;
	flex-direction: column;
}

.DeviceView .infobox {
	flex-grow: 0;
}

.infoBox {
	margin-left: 1em;
	border: 1px solid black;
	width: 20em;
	float: right;
}

.infoBox td {
	vertical-align: top;
}

.infoBox .heading {
	text-align: center;
	font-weight: bold;
}
