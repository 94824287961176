.PageDebug .PageContent {
	display: flex;
}

.PageDebug ul.links {
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.PageDebug li a {
	display: block;
	margin: 0;
	padding: 1ex 4ex 1ex 2ex;
	cursor: pointer;
	text-decoration: none;
	xcolor: var(--sidebar-link);
	transition-duration: 0.3s;
	white-space: nowrap;
}

.PageDebug li a:hover {
	background-color: #ccca;
}

.PageDebug i {
	margin-right: 0.75ex;
	font-style: normal;
	background: #fff8;
	padding: 4px 6px 4px 6px;
	border-radius: 4px;
	text-shadow: 2px 2px 4px #0008;
}
