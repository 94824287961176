.Loading {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	filter: drop-shadow(0px 0px 18px var(--uq-primary-purple));
}

.Loading .background {
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--uq-primary-purple);
	clip-path: circle(75px);
	min-height: 150px;
	min-width: 150px;
	isolation: isolate;
}

@keyframes scroll {
	0% {
		background-position: 0px;
	}
	100% {
		background-position: -200px;
	}
}

.Loading .animation {
	display: inline-block;
	width: 100px;
	height: 125px;
	background-image: url('sine.svg');
	background-size: 200px;
	background-repeat: repeat-x;
	background-position: 0px;
	animation-duration: 1s;
	animation-name: scroll;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.Loading .fadeout {
	height: 100%;
	background: linear-gradient(
		90deg,
		#51247aff 5%,
		#51247a88 50%,
		#51247a00 70%,
		#51247a00 90%,
		#51247aff 98%
	);
}
