.ErrorMessage {
	background: #fcc;
	color: black;
	border: 1px solid red;
	border-radius: 4px;
	margin: 1em;

	/* Required for child pre to scroll instead of expanding us */
	display: flex;
	overflow: auto;
}

.ErrorMessage .icon {
	font-size: 250%;
	vertical-align: middle;
	filter: drop-shadow(0px 0px 4px #c00);
	margin-right: 1ex;
}

.ErrorMessage .box {
	display: flex;
	flex-direction: row;
	padding: 1em;

overflow: auto;
xflex-grow: 1;
}

.ErrorMessage .box .content {
	display: flex;
	flex-direction: column;
overflow: auto;
xflex-grow: 1;
}

.ErrorMessage h3 {
	margin: 0;
	padding: 0;
}

.ErrorMessage ul {
	margin: 1ex 0 0 2ex;
	padding: 0;
}

.ErrorMessage .box .content .message {
	xflex-grow: 1;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.ErrorMessage pre {
	/* This makes the pre itself scroll, as opposed to the parent element.
	   Requires all parents to be flex and have overflow:auto on them. */
	overflow: auto;
}

.ShowMore .moreText {
	padding-left: 1ex;
	cursor: pointer;
	color: var(--uq-secondary-blue);
	font-size: 75%;
}
