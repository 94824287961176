.ExpandingText {
	display: inline-grid;
	align-items: stretch;
}

.ExpandingText input {
	grid-area: 1/1;
	width: auto;
	/* min-width is not set here as it depends on the placeholder, so it's
	   left to the user of this component to set. */
}

.ExpandingText::after {
	content: attr(data-value) ' ';
	box-sizing: border-box;
	color: transparent;
	grid-area: 1/1;
	visibility: hidden;
	white-space: pre-wrap;

	/* Give the user some more empty space on the right to click in */
	padding-left: 2em;

	/* Stop it expanding beyond the parent's width */
	overflow: hidden;
}
