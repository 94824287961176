/*
 * P&F Demand Response Engine
 *
 * Primary styling for elements shared across the entire application.
 */

:root {
	--uq-primary-purple: #51247a;
	--uq-secondary-lightpurple: #962A8B;
	--uq-secondary-red: #E62645;
	--uq-secondary-green: #2EA836;
	--uq-secondary-gold: #BB9D65;
	--uq-secondary-orange: #EB602B;
	--uq-secondary-yellow: #FBB800;
	--uq-secondary-blue: #4085C6;
	--uq-secondary-aqua: #00A2C7;
	--uq-secondary-neutral: #D7D1CC;
	--uq-secondary-darkgrey: #999490;

	--uq-purple: #51247a;
	--uq-purple-light: #6c30a2;
	--input-outline: #b15cff;
	/*--uq-purple-dark: #48206c;*/

	--error-fg: white;
	--error-bg: var(--uq-secondary-red);

	--error-text: var(--uq-secondary-red);

	--accent-neutral: var(--uq-secondary-neutral);
	--accent-error: var(--uq-secondary-red);
	--accent-success: var(--uq-secondary-green);
	--accent-warning: var(--uq-secondary-yellow);

	--border-accent-neutral: 4px solid var(--accent-neutral);
	--border-accent-error: 4px solid var(--accent-error);
	--border-accent-warning: 4px solid var(--accent-warning);
	--border-accent-success: 4px solid var(--accent-success);

	/* Form fields blend into the background using these values */
	--field-background-colour: #000;
	--field-background-opacity: 0.3;

	/* Sidebar-specific colours */
	--sidebar-background: var(--uq-primary-purple);
	--sidebar-background-hover: var(--uq-secondary-lightpurple);
	--sidebar-heading: #fff;
	--sidebar-link: #fff;

	/* Tables */
	--table-thead-fg: white;
	--table-thead-bg: var(--uq-secondary-green);
	--xtable-row-groupheader: #51247a22;
	--table-row-groupheader: #D7D1CC50;
	--table-row-hover: #D7D1CC80;

	--highlight-fade-start: #FFFF80;
}

@import url('https://fonts.googleapis.com/css2?family=Oxanium:wght@400;700&display=swap');
body, input {
	font-family: 'Oxanium', sans-serif;
	font-size: 12pt;

	color: white;
	background-color: var(--uq-purple);
}

.App {
	display: flex;
	flex-direction: row;
}

/* The part of the page to the left of the sidebar */
.AppContent {
	width: 100%;
	height: 100vh;
	overflow: auto;
	display: flex;
	flex-direction: column;
}

table {
	/* Default to no gaps between cells, so that cells with links don't have any
	   dead zones (like the DeviceList table). */
	/*
	border-collapse: collapse;
	border-spacing: 0;
	*/
	/* Can't do this because then we have to use borders to simulate gaps in
	   cells. */
	border-collapse: separate;
	border-spacing: 2;
}

table th {
	color: var(--table-thead-fg);
	background-color: var(--table-thead-bg);
	padding: 0.5ex 1ex;
	text-align: left;
}

/* Make table rows highlight on mouseover, if table class is "rowHover" and
   table row class does NOT contain "pad". */
table.rowHover tbody tr:not(.pad):hover {
	background-color: var(--table-row-hover);
}

/* Links */

a.external:before {
	content: '↗';
	text-decoration: none;
	display: inline-block;
	padding: 0 0.1ex;
}

/*
 * Form styles
 */

input,
button {
	border: none;
	border-radius: 8px;
}

input {
	box-sizing: border-box; /* Don't grow outside parent box */
	padding: 0.75ex;
	color: black;
	background-color: white;
	box-shadow: #4046 1px 2px 6px 0px inset;
}

input:focus {
	/* Change the default blue outline to match the colour scheme */
	outline: var(--input-outline) solid 2px;
}

input[disabled] {
	opacity: 0.7;
}

button {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 2.5em;

	cursor: pointer;
	padding: 1ex 3ex;
	/* Make the button a bit raised/3D looking */
	box-shadow: #ccc 0px -2px 6px 0px inset,
	            #000 -3px -3px 3px -4px inset;
}

button:disabled,
button[disabled] {
	box-shadow: #ccc 0px -2px 1px 0px inset;
	cursor: not-allowed;
	background-color: #ddd;
}

button:hover:not([disabled]) {
	background-color: #f5f5f5;
}

button:active:hover:not([disabled]) {
	/* Make the button a bit sunk/3D looking when pressed */
	box-shadow: #3038 2px 2px 6px 0px inset,
	            #fefb 0px -2px 6px 0px inset;
}

button:active:hover:not([disabled]) > span {
	/* Move the button text by a few pixels when pressed */
	transform: translate(2px, 2px);
}
