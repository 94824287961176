/* Content area including headings, expanded to full vertical height */
.Page {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	overflow: auto; /* Needed for child elements to scroll independently */
}

.Page .PageHeader {
	color: #fffb;
	background-color: var(--uq-purple);
	margin: 0;

	/* Give enough vertical padding that buttons and the quick open fit within */
	padding: 0.75ex 0.5ex;

	/* Set up so action buttons can appear in the heading */
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Page .PageHeader .title {
	display: flex;
	flex-direction: row;
	align-items: center;

	/* Add enough height so it's the same if there are action buttons or not */
	height: 3ex;
}

.Page .PageHeader .actions {
	margin-left: 1em;
	display: flex;
	flex-direction: row;
	column-gap: 1ex;
}

/* Content area below the headings, expanded to full vertical height */
.Page .PageContent {
	flex-grow: 1;
	padding: 1.5em 1em 1em 1.5em;
	color: black;
	background-color: white;
	box-shadow: 8px 8px 10px -8px #303 inset;
	overflow: scroll;
}
