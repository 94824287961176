.status {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	row-gap: 1em;
	column-gap: 1em;
}

.status div {
	flex: 1 1 0;
	background-color: white;
	xborder: 2px solid var(--uq-secondary-green);
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	padding: 1em;
}

.status h1 {
	margin: 0;
	font-size: 100%;
}

.PageHome .PageContent {
	background-color: #efefef;
}
