.Button {
	display: inline-flex;
	flex-direction: row;
	align-items: center;

	height: 2.5em;
}

.Button.small {
	height: 4ex;
	padding: 1ex;
}

.Button.link .text {
	text-decoration: underline;
}

.Button.link:hover,
.Button.link:active,
.Button.link:active:hover:not([disabled]),
.Button.link {
	background: transparent;
	box-shadow: none;
	padding: 0 0;
}
.Button.link:active:hover:not([disabled]) > span {
	transform: none;
}
.Button.link:active:hover:not([disabled]) {
	color: var(--uq-secondary-yellow);
}
.Button.link .icon {
	margin-right: 0.5ex;
}

.Button .icon {
	font-size: 125%;
	margin-right: 1ex;

	/* Grid allows us to easily overlay all the icons for transition effects */
	display: grid;
}

/* Make all the sub-icons appear on top of each other so the fading works */
.Button .icon span {
	grid-row: 1;
	grid-column: 1;
}

/* Smoothly fade the icons when the `fade` class is also set */
.Button .icon .fade {
	transition: opacity 0.3s;
}

/* Animation to make a rocket icon look like it's trying to take off */
.Button .icon .active {
	animation: button-rocket 0.4s cubic-bezier(.86,0,.07,1) infinite;
}
@keyframes button-rocket {
	  0% { transform: translate( 0px,  0px); }
	 10% { transform: translate( 1px, -1px); }
	 20% { transform: translate(-1px,  1px); }
	 30% { transform: translate( 0px, -1px); }
	 40% { transform: translate(-1px,  0px); }
	 50% { transform: translate( 1px, -1px); }
	 60% { transform: translate(-1px,  1px); }
	 70% { transform: translate( 0px, -1px); }
	 80% { transform: translate( 1px,  0px); }
	 90% { transform: translate( 0px, -1px); }
	100% { transform: translate(-1px,  1px); }
}
