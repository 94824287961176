.Sidebar {
	cursor: default;
	color: var(--sidebar-heading);
	background-color: var(--sidebar-background);

	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: auto;
}

.Sidebar h1 {
	color: white;
	opacity: 0.5;
	margin: 0.5ex 0 0.5ex 0;
	padding: 0;
	text-align: center;
}
.Sidebar h1 a,
.Sidebar h1 a:visited {
	color: inherit;
	text-decoration: none;
}

.Sidebar ul {
	/* Make just the menu scroll, but not the main "DRE" heading. */
	overflow: scroll;

	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.Sidebar p {
	padding: 0 1ex;
	margin: 1ex 0 0.5ex 0;
	font-size: 80%;
	font-weight: bold;
}

.Sidebar li {
	margin: 0;
	padding: 0;
}

.Sidebar li a {
	display: block;
	margin: 0;
	padding: 1ex 4ex 1ex 2ex;
	cursor: pointer;
	text-decoration: none;
	color: var(--sidebar-link);
	transition-duration: 0.3s;
	white-space: nowrap;
}

.Sidebar li a:hover {
	background-color: var(--sidebar-background-hover);
}

.Sidebar i {
	margin-right: 0.75ex;
	font-style: normal;
	background: #fff8;
	padding: 4px 6px 4px 6px;
	border-radius: 4px;
	text-shadow: 2px 2px 4px #0008;
}
