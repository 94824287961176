.Information {
	display: inline;
	position: relative;
	padding: 0 1ex;
	cursor: default;
}

.Information i {
	font-style: normal;
	font-weight: normal;
}

.Information .info-popup {
	visibility: hidden;
	display: inline-block;
	font-weight: normal;
}

.Information:hover .info-popup {
	visibility: visible;
}
